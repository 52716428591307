import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DarkModeToggle from "react-dark-mode-toggle";
import {
  faGlobe,
  faBars,
  faFile,
  faEdit,
  faEye,
  faEyeSlash,
  faBug,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
  FormFeedback,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Bugs from "../bug/bugs";
import "./sidenav.scss";
import { getCokkie, logout } from "../../views/login/Login";
import axios from "axios";
import getDataFromApi from "../getDataFromApi";
import Notifications from "../notifications/Notifications";
import { ReactStrapModal } from "../reactsrap_modal/ReactStrapModel";
import { ContextManager } from "../../logic/context/ContextManager";
import { AIModel } from "../../data/enums/AIModel";
import AiModals from "../ai-assist/AiModals";
// const url = `${window['ENV'].API_URL}/view-api/home/projects`;
export var navActive = () => { };
export default function Sidenav() {
  const role = localStorage.getItem("role");
  const view = window.location.pathname.split("/")[1];
  const [open, setOpen] = React.useState(false);
  const [path, setPath] = React.useState();
  const [active, setActive] = React.useState(
    view == "" || view == "annotations" || view == "timeseriesannotation" ? "dashboard" : view
  );
  const [isDarkMode, setIsDarkMode] = useState(false);

  function getTheme() {
    // Default to Light if key not present.
    return window.localStorage.getItem("theme") || "light";
  }
  function getOppositeTheme() {
    const theme = getTheme();
    if (theme === "light") return "dark";
    else return "light";
  }
  function onSubmit(formData, result) {
    localStorage.setItem("hotKeys", JSON.stringify(result?.detail?.hot_keys ?? []));
    ContextManager.updateHotkeys();
  }
  const html = document.querySelector("html");
  useEffect(() => {
    if (window.localStorage.getItem("theme") == "dark") {
      html.setAttribute("data-theme", "dark");
      setIsDarkMode(true);
    }
  }, []);

  function nightmode() {
    if (html) html.setAttribute("data-theme", getTheme());
    const newTheme = getOppositeTheme();
    html.setAttribute("data-theme", newTheme);
    window.localStorage.setItem("theme", newTheme);
  }
  navActive = () => setActive("projects");
  return (
    <div className="d-flex align-items-center py-0 w-100 top-nav bg-white shadow">
      <ul className="nav nav-pills align-items-center mb-auto">
        <li className="nav-item p-3">
          <a
            href={`/projects`}
            className="navbar-brand d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="28"
              viewBox="0 0 31 28"
              fill="none"
            >
              <path
                d="M23 3h-9C7.925 3 3 7.925 3 14v0c0 6.075 4.925 11 11 11h9M21 14h7"
                stroke="#3755d3"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </svg>
            <span className="f-14 ps-2">DeepEdge</span>
          </a>
        </li>
        <li
          className={
            active == "projects" ? "nav-item p-3 active" : "nav-item p-3 "
          }
        >
          <Link
            className="sidenav-link"
            to="/projects"
            onClick={() => setActive("projects")}
          >
            <FontAwesomeIcon icon={faBars} size="lg" color="#fff" />
            <span>Projects</span>
          </Link>
        </li>
        <li
          className={
            active == "dashboard" ? "nav-item p-3 active" : "nav-item p-3 "
          }
        >
          <a
            className="sidenav-link"
            href="/"
            onClick={() => setActive("dashboard")}
          >
            <FontAwesomeIcon icon={faBars} size="lg" color="#fff" />
            <span>Platform</span>
          </a>
        </li>

        <li
          className={active == "hub" ? "nav-item p-3 active" : "nav-item p-3 "}
        >
          <Link
            to="/hub"
            className="sidenav-link"
            onClick={() => setActive("hub")}
          >
            <FontAwesomeIcon icon={faGlobe} size="lg" color="#fff" />
            <span>Hub</span>
          </Link>
        </li>
        <li
          className={active == "docs" ? "nav-item p-3 active" : "nav-item p-3 "}
        >
          <Link
            to="/docs/Getting Started"
            className="sidenav-link"
            onClick={() => setActive("docs")}
          >
            <FontAwesomeIcon icon={faFile} size="lg" color="#fff" />
            <span>Docs</span>
          </Link>
        </li>
        {role == "admin" && (
          <li
            className={
              active == "admin" ? "nav-item p-3 active" : "nav-item p-3 "
            }
          >
            <Link
              to="/admin"
              className="sidenav-link"
              onClick={() => setActive("admin")}
            >
              <FontAwesomeIcon icon={faFile} size="lg" color="#fff" />
              <span>Admin</span>
            </Link>
          </li>
        )}
      </ul>
      <div
        className="ms-auto"
        id="themeToggle"
        color="trasperent"
        onClick={nightmode}
      >
        <DarkModeToggle
          onChange={() => setIsDarkMode(!isDarkMode)}
          // onClick={nightmode}
          checked={isDarkMode}
          size={40}
        />
      </div>


      <Notifications />
      {/* <Nav className="mt-1">
        <UncontrolledDropdown nav navbar="true">
          <DropdownToggle nav id="bugs">
            <FontAwesomeIcon icon={faBug} style={{ fontSize: "18px" }} />
          </DropdownToggle>
          <DropdownMenu
            className="grey-card p-4 bg-grey mt-3"

            style={{ width: "30vw", height: "75vh", overflow: "auto" }}
          >
            <Bugs />
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav> */}
      {/* <Nav className="mt-1">
        <UncontrolledDropdown nav navbar="true">
          <DropdownToggle nav id="models">
            <FontAwesomeIcon  style={{ fontSize: "18px" }} />
          </DropdownToggle>
          <DropdownMenu
            className="grey-card p-4 bg-grey mt-3 model-list"

            style={{ width: "350px", MaxHeight: "75vh", overflow: "auto" }}
          > */}
            {/* <Bugs /> */}
             {/* <AiModals/> */}
          {/* </DropdownMenu>
        </UncontrolledDropdown>
      </Nav> */}
      <Nav className="pe-2">
        <UncontrolledDropdown nav navbar="true">
          <DropdownToggle nav>
            <img
              className="small-img rounded-img"
              src={localStorage.getItem("navImage")}
              alt=""
            />
          </DropdownToggle>
          <DropdownMenu className="grey-card" end>
            <DropdownItem
              className="fw-bold"
              style={{
                maxWidth: "150px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Hi {localStorage.getItem("name")}!
            </DropdownItem>
            <DropdownItem onClick={() => setOpen(true)}>Profile</DropdownItem>
            <DropdownItem onClick={() => setPath("forms/edit_hotkey")}>Hotkeys</DropdownItem>

            <DropdownItem divider />
            <DropdownItem onClick={logout}>logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      <div>
        <ViewProfile open={open} setOpen={setOpen} />
        <ReactStrapModal open={path?.length > 0} close={() => setPath()} path={path} onSubmit={onSubmit} />
      </div>
    </div>
  );
}
function ViewProfile({ open, setOpen }) {
  const [edit, setEdit] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
  });
  const [data, setData] = React.useState({});
  const [editPassword, setEditPassword] = React.useState(false);
  const [validation, setValidation] = React.useState({});
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        if (fileReader.result.includes("data:image"))
          resolve(fileReader.result);
        else {
          alert("select a valid image");
          reject("404");
        }
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  React.useEffect(() => {
    if (open == true) {
      const url = `${window['ENV'].API_URL}/view-api/home/users`;
      getDataFromApi(url, "get")
        .then((result) => {
          setData(result);
        })
        .catch((e) => console.log(e.message));
    }
    return () => {
      setData({});
    };
  }, [open]);
  return (
    <Modal centered scrollable size="" isOpen={open}>
      <ModalHeader
        toggle={() => {
          setEdit(false);
          setOpen(false);
        }}
      >
        Profile
        <Button
          color="transparent"
          className="edit-profile"
          onClick={(e) => setEdit(!edit)}
        >
          <FontAwesomeIcon
            icon={faEdit}
            color={edit ? "#5c5cb8" : "#000"}
          ></FontAwesomeIcon>
        </Button>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="img-wrapper d-flex justify-content-center">
            <img
              className="mb-3 profile-img"
              src={data.profile_image}
              alt="AVATAR.jpg"
            />
            {edit && (
              <>
                <Label for="file-upload" className="file-upload">
                  <FontAwesomeIcon
                    icon={faEdit}
                    color={"#ced4da"}
                    size="3x"
                  ></FontAwesomeIcon>
                </Label>
                <Input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="file-upload"
                  onChange={async (e) => {
                    if (e.target?.files?.length) {
                      const base64 = await convertBase64(e.target.files[0]);
                      setData({ ...data, profile_image: base64 });
                    }
                  }}
                />
              </>
            )}
          </div>
          <div className="d-flex justify-content-center fs-5">
            <b>Role:</b>
            <span className="mx-2 fst-italic">{data.role}</span>
          </div>
          <FormGroup>
            <Label for="name">Name:</Label>
            <Input
              type="text"
              id="name"
              placeholder="Name"
              readOnly={!edit}
              value={data.name}
              valid={validation?.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            ></Input>
            <FormFeedback valid={validation?.name}>
              {validation.name}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email:</Label>
            <Input
              type="Email"
              id="email"
              placeholder="example@example.com"
              value={data.email}
              readOnly={!edit}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              invalid={validation.email ? true : false}
            ></Input>
          </FormGroup>
          <FormFeedback>{validation.email}</FormFeedback>
          {edit && (
            <>
              <div class="form-check form-switch d-flex align-items-center mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={editPassword}
                  id="flexSwitchCheckDefault"
                  onChange={(e) => setEditPassword(e.target.checked)}
                />
                <Label
                  class="form-check-label mx-2"
                  for="flexSwitchCheckDefault"
                >
                  Update Password
                </Label>
              </div>
              {editPassword && (
                <>
                  <FormGroup>
                    <Label for="old-password">Type Old Password:</Label>
                    <div className="password-container">
                      <Input
                        type={showPassword.oldPassword ? "text" : "Password"}
                        autocomplete="new-password"
                        id="old-password"
                        placeholder="Old password"
                        readOnly={!edit}
                        onChange={(e) =>
                          setData({ ...data, oldPassword: e.target.value })
                        }
                        invalid={!validation.oldPassword ? false : true}
                      ></Input>
                      <FormFeedback>{validation.oldPassword}</FormFeedback>
                      <Button
                        onClick={(e) =>
                          setShowPassword({
                            ...showPassword,
                            oldPassword: !showPassword.oldPassword,
                          })
                        }
                        className="eye-btn"
                      >
                        <FontAwesomeIcon
                          icon={showPassword.oldPassword ? faEye : faEyeSlash}
                        />
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="new-password">Type New Password:</Label>
                    <div className="password-container">
                      <Input
                        type={showPassword.newPassword ? "text" : "Password"}
                        autocomplete="new-password"
                        id="new-password"
                        placeholder="New Password"
                        readOnly={!edit}
                        onChange={(e) =>
                          setData({ ...data, newPassword: e.target.value })
                        }
                        invalid={!validation.oldPassword ? false : true}
                      ></Input>
                      <Button
                        onClick={(e) =>
                          setShowPassword({
                            ...showPassword,
                            newPassword: !showPassword.newPassword,
                          })
                        }
                        className="eye-btn"
                      >
                        <FontAwesomeIcon
                          icon={showPassword.newPassword ? faEye : faEyeSlash}
                        />
                      </Button>
                    </div>
                  </FormGroup>
                </>
              )}
              <Button
                color="primary"
                className="block-center"
                onClick={async () => {
                  try {
                    const cokkie = getCokkie("jwtToken");
                    const url = `${window['ENV'].API_URL}/view-api/home/users`;
                    const headers = {
                      accept: "application/json",
                      Authorization: `Bearer ${cokkie}`,
                    };
                    const response = await axios.put(url, data, { headers });
                    console.log(response);
                    alert(response.data);
                    if (response.status == 200) {
                      localStorage.setItem("navImage", data.profile_image);
                      setEdit(false);
                      setOpen(false);
                    }
                  } catch (e) {
                    setValidation(e.response.data);
                    console.log(e.response.data);
                  }
                }}
              >
                Update Profile
              </Button>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}
