import { IPoint } from "../../interfaces/IPoint";
import { IRect } from "../../interfaces/IRect";
import { RectUtil } from "../../utils/RectUtil";
import { DrawUtil } from "../../utils/DrawUtil";
import { store } from "../..";
import { ImageData, LabelRect } from "../../store/labels/types";
import {
  updateActiveLabelId,
  updateActiveLabelNameId,
  updateFirstLabelCreatedFlag,
  updateHighlightedLabelId,
  updateImageDataById,
  updateImageDataByIdwithoutundo,
} from "../../store/labels/actionCreators";
import { PointUtil } from "../../utils/PointUtil";
import { RectAnchor } from "../../data/RectAnchor";
import { RenderEngineSettings } from "../../settings/RenderEngineSettings";
import { updateCustomCursorStyle } from "../../store/general/actionCreators";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import { EditorData } from "../../data/EditorData";
import { BaseRenderEngine } from "./BaseRenderEngine";
import { RenderEngineUtil } from "../../utils/RenderEngineUtil";
import { LabelType } from "../../data/enums/LabelType";
import { EditorActions } from "../actions/EditorActions";
import { GeneralSelector } from "../../store/selectors/GeneralSelector";
import { LabelStatus } from "../../data/enums/LabelStatus";
import { LabelUtil } from "../../utils/LabelUtil";
// import useMousePosition from "./UseMousePosition";
import { EditorModel } from "../../staticModels/EditorModel";
var draw = true;
var isDragging = false;
var mousedrag={x:0,y:0}
export class RectRenderEngine extends BaseRenderEngine {
  // =================================================================================================================
  // STATE
  // =================================================================================================================

  private startCreateRectPoint: IPoint;
  private startResizeRectAnchor: RectAnchor;

  public constructor(canvas: HTMLCanvasElement) {
    super(canvas);
    this.labelType = LabelType.RECT;
    draw = true;
  }
  // publick mousePosition = useMousePosition();
  // =================================================================================================================
  // EVENT HANDLERS
  // =================================================================================================================

  public mouseDownHandler = (data: EditorData) => {
    const isMouseOverImage: boolean = RenderEngineUtil.isMouseOverImage(data);
    // const isMouseOverBox: boolean = RenderEngineUtil.isMouseOverbox(data);
    if (isMouseOverImage) {
      // console.log(data.mousePositionOnViewPortContent,
      //   data.viewPortContentImageRect)
      const rectUnderMouse: LabelRect = this.getRectUnderMouse(data);
      if (!!rectUnderMouse) {
        draw = false
       
        const rect: IRect = this.calculateRectRelativeToActiveImage(
          rectUnderMouse.rect,
          data
        );
        const anchorUnderMouse: RectAnchor = this.getAnchorUnderMouseByRect(
          rect,
          data.mousePositionOnViewPortContent,
          data.viewPortContentImageRect
        );
        if (
          !!anchorUnderMouse &&
          rectUnderMouse.status === LabelStatus.ACCEPTED
        ) {
          store.dispatch(updateActiveLabelId(rectUnderMouse.id));
          this.startRectResize(anchorUnderMouse);
          console.log("on rect line")
        } else {
          isDragging=true;
          if (!!LabelsSelector.getHighlightedLabelId())
            store.dispatch(
              updateActiveLabelId(LabelsSelector.getHighlightedLabelId())
            );
            mousedrag.x=data.mousePositionOnViewPortContent.x
            mousedrag.y=data.mousePositionOnViewPortContent.y
        }
      }
    }
    else {
      draw = false
    }
  };
  public updateMousePosition = ev => {
       return ev.clientX;
  };

  public mouseUpHandler = (data: EditorData) => {
    const isMouseOverImage: boolean = RenderEngineUtil.isMouseOverImage(data);
    const scale: number = RenderEngineUtil.calculateImageScale(data);
    if (!!data.mousePositionOnViewPortContent && draw) {
      
      //  window.alert( EditorModel.mousePositionOnViewPortContent.x/scale)
     if(data.mousePositionOnViewPortContent.x-data.absoluteViewPortContentScrollPosition.x+4 < data.viewPortSize.width && data.mousePositionOnViewPortContent.x-data.absoluteViewPortContentScrollPosition.x >0 && data.mousePositionOnViewPortContent.y-data.absoluteViewPortContentScrollPosition.y+4 < data.viewPortSize.height && data.mousePositionOnViewPortContent.y-data.absoluteViewPortContentScrollPosition.y >0){
      console.log()
      this.startRectCreation(data.mousePositionOnViewPortContent);
      draw = false
     }

    }
    else {
      if (!!isMouseOverImage) {
       
        // window.alert("some yes")
        const mousePositionSnapped: IPoint = RectUtil.snapPointToRect(
          data.mousePositionOnViewPortContent,
          data.viewPortContentImageRect
        );
        const activeLabelRect: LabelRect = LabelsSelector.getActiveRectLabel();

        if (
          !!this.startCreateRectPoint &&
          !PointUtil.equals(this.startCreateRectPoint, mousePositionSnapped)
        ) {
          const minX: number = Math.min(
            this.startCreateRectPoint.x,
            mousePositionSnapped.x
          );
          const minY: number = Math.min(
            this.startCreateRectPoint.y,
            mousePositionSnapped.y
          );
          const maxX: number = Math.max(
            this.startCreateRectPoint.x,
            mousePositionSnapped.x
          );
          const maxY: number = Math.max(
            this.startCreateRectPoint.y,
            mousePositionSnapped.y
          );

          const rect = {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
          };
          this.addRectLabel(
            RenderEngineUtil.transferRectFromImageToViewPortContent(rect, data)
          );
        }

        if ( !!activeLabelRect) {
          // console.log(isDragging)
          // if(isDragging){
            
          // }
          const rect: IRect = this.calculateRectRelativeToActiveImage(
            activeLabelRect.rect,
            data
          );
        
          if(!!this.startResizeRectAnchor){
            const startAnchorPosition: IPoint = PointUtil.add(
              this.startResizeRectAnchor.position,
              data.viewPortContentImageRect
            );
            const delta: IPoint = PointUtil.subtract(
              mousePositionSnapped,
              startAnchorPosition
            );
            const resizeRect: IRect = RectUtil.resizeRect(
              rect,
              this.startResizeRectAnchor.type,
              delta
            );
            
            const scaledRect: IRect = RectUtil.scaleRect(resizeRect, scale);
  
            const imageData = LabelsSelector.getActiveImageData();
            imageData.labelRects = imageData.labelRects.map(
              (labelRect: LabelRect) => {
                if (labelRect.id === activeLabelRect.id) {
                  return {
                    ...labelRect,
                    rect: scaledRect,
                  };
                }
                return labelRect;
              }
            );
            store.dispatch(updateImageDataById(imageData.image_id, imageData));
          }
          else{
            // this.canvas.style.cursor = "move";
            // store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
            console.log(mousePositionSnapped,data)
            if(isDragging){
              var x=mousedrag.x-mousePositionSnapped.x
              var y=mousedrag.y-mousePositionSnapped.y
              rect.x=rect.x-x
              rect.y=rect.y-y
              if(rect.x <1){
                rect.x=0
              }
              if(rect.y <1){
                rect.y=0
              }
              if(rect.x >= data.viewPortContentImageRect.width-rect.width){
                rect.x=data.viewPortContentImageRect.width-rect.width
              }
              if(rect.y >= data.viewPortContentImageRect.height-rect.height){
                rect.y=data.viewPortContentImageRect.height-rect.height
              }
              const scaledRect: IRect = RectUtil.scaleRect(rect, scale);
    
              const imageData = LabelsSelector.getActiveImageData(); 
              // this.addRectLabel(
              //   RenderEngineUtil.transferRectFromImageToViewPortContent(rect, data)
              // ); 
              imageData.labelRects = imageData.labelRects.map(
                (labelRect: LabelRect) => {
                  if (labelRect.id === activeLabelRect.id) {
                    return {
                      ...labelRect,
                      rect: scaledRect,
                    };
                  }
                  return labelRect;
                }
              );
              store.dispatch(updateImageDataById(imageData.image_id, imageData));
            }
          
          }
        
        }
      }
      this.endRectTransformation();
      draw = true
      isDragging = false
    }
  };
  public mouseMoveHandler = (data: EditorData) => {
    const isOverImage: boolean = RenderEngineUtil.resizeChecker(data);
    
    // if (
    //   !!data.viewPortContentImageRect &&
    //   !!data.mousePositionOnViewPortContent
    // ) {
      

      if (isOverImage ) {
        if(!this.startResizeRectAnchor){
          const labelRect: LabelRect = this.getRectUnderMouse(data);
          if (!!labelRect && !this.isInProgress()) {
            if (LabelsSelector.getHighlightedLabelId() !== labelRect.id) {
              store.dispatch(updateHighlightedLabelId(labelRect.id));
            }
          } else {
            if (LabelsSelector.getHighlightedLabelId() !== null) {
              store.dispatch(updateHighlightedLabelId(null));
            }
          }
         
        }
      
        
      }
    

    // }
   
    if(isDragging){
      const activeLabelRect: LabelRect = LabelsSelector.getActiveRectLabel();
      const rect: IRect = this.calculateRectRelativeToActiveImage(
        activeLabelRect.rect,
        data
      );
      // if(rect.x>0 && rect.y>0){
        if(data.mousePositionOnViewPortContent.x>0 && data.mousePositionOnViewPortContent.y >0 ){
          const mousePositionSnapped: IPoint = RectUtil.snapPointToRect(
            data.mousePositionOnViewPortContent,
            data.viewPortContentImageRect
          );
        
          // }
       
          const scale: number = RenderEngineUtil.calculateImageScale(data);
         
          if (!!activeLabelRect  && !draw) {
            // this.canvas.style.cursor = "move";
            // store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
    
            console.log(mousePositionSnapped);
            var x = mousedrag.x - mousePositionSnapped.x;
            var y = mousedrag.y - mousePositionSnapped.y;
            rect.x = rect.x - x;
            rect.y = rect.y - y;
            if(rect.x <1){
              rect.x=0
            }
           
            if(rect.y <1){
              rect.y=0
            }
            if(rect.x >= data.viewPortContentImageRect.width-rect.width){
              rect.x=data.viewPortContentImageRect.width-rect.width
            }
            if(rect.y >= data.viewPortContentImageRect.height-rect.height){
              rect.y=data.viewPortContentImageRect.height-rect.height
            }
            mousedrag.x=mousePositionSnapped.x
            mousedrag.y=mousePositionSnapped.y
            const scaledRect: IRect = RectUtil.scaleRect(rect, scale);
    
            const imageData = LabelsSelector.getActiveImageData();
            // this.addRectLabel(
            //   RenderEngineUtil.transferRectFromImageToViewPortContent(rect, data)
            // );
            imageData.labelRects = imageData.labelRects.map(
              (labelRect: LabelRect) => {
                if (labelRect.id === activeLabelRect.id) {
                  return {
                    ...labelRect,
                    rect: scaledRect,
                  };
                }
                return labelRect;
              }
            );
            store.dispatch(updateImageDataByIdwithoutundo(imageData.image_id, imageData));
          }
        }
      
      // }
     
    }
  };
  public pasteHandler(): void {
    //  var test = [...localStorage.getItem('copy')]
    // this.imageDataCache[0].rect.x=10;
    // this.imageDataCache[0].rect.y=10;
    console.log(this.imageDataCache)
    this.imageDataCache.forEach((rectLabel) => {
      store.dispatch(updateActiveLabelNameId(rectLabel.labelId));
      this.addRectLabel(rectLabel.rect);
    });
  }


  // =================================================================================================================
  // RENDERING
  // =================================================================================================================

  public render(data: EditorData) {
    const activeLabelId: string = LabelsSelector.getActiveLabelId();
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    if (imageData) {
      imageData.labelRects.forEach((labelRect: LabelRect) => {
        // console.log(labelRect.show)
        if (labelRect.show) {
          if (labelRect.status === LabelStatus.ACCEPTED && labelRect.id === activeLabelId) {
            this.drawActiveRect(labelRect, data);
          }
          else {
            this.drawInactiveRect(labelRect, data);
          }
        }
      });
      this.drawCurrentlyCreatedRect(
        data.mousePositionOnViewPortContent,
        data.viewPortContentImageRect
      );
      this.updateCursorStyle(data);
    }
  }

  private drawCurrentlyCreatedRect(mousePosition: IPoint, imageRect: IRect) {
    if (!!this.startCreateRectPoint) {
      const mousePositionSnapped: IPoint = RectUtil.snapPointToRect(
        mousePosition,
        imageRect
      );
      const activeRect: IRect = {
        x: this.startCreateRectPoint.x,
        y: this.startCreateRectPoint.y,
        width: mousePositionSnapped.x - this.startCreateRectPoint.x,
        height: mousePositionSnapped.y - this.startCreateRectPoint.y,
      };
      const activeRectBetweenPixels =
        RenderEngineUtil.setRectBetweenPixels(activeRect);
      const lineColor: string = BaseRenderEngine.resolveLabelLineColor(
        null,
        true
      );
      DrawUtil.drawRect(
        this.canvas,
        "select-label",
        activeRectBetweenPixels,
        lineColor,
        RenderEngineSettings.LINE_THICKNESS
      );
    }
  }

  private drawInactiveRect(labelRect: LabelRect, data: EditorData) {
    const rectOnImage: IRect =
      RenderEngineUtil.transferRectFromViewPortContentToImage(
        labelRect.rect,
        data
      );
    const highlightedLabelId: string = LabelsSelector.getHighlightedLabelId();
    const LabelName: string = BaseRenderEngine.resolveLabelLineName(
      labelRect.labelId
    );
    const displayAsActive: boolean =
      labelRect.status === LabelStatus.ACCEPTED &&
      labelRect.id === highlightedLabelId;
    const lineColor: string = BaseRenderEngine.resolveLabelLineColor(
      labelRect.labelId,
      displayAsActive
    );
    const anchorColor: string =
      BaseRenderEngine.resolveLabelAnchorColor(displayAsActive);
    this.renderRect(
      rectOnImage,
      LabelName,
      displayAsActive,
      lineColor,
      anchorColor
    );
  }
  private hide() {

  }
  private drawActiveRect(labelRect: LabelRect, data: EditorData) {
    let rect: IRect = this.calculateRectRelativeToActiveImage(
      labelRect.rect,
      data
    );
    if (!!this.startResizeRectAnchor ) {
      const startAnchorPosition: IPoint = PointUtil.add(
        this.startResizeRectAnchor.position,
        data.viewPortContentImageRect
      );
      const endAnchorPositionSnapped: IPoint = RectUtil.snapPointToRect(
        data.mousePositionOnViewPortContent,
        data.viewPortContentImageRect
      );
      const delta = PointUtil.subtract(
        endAnchorPositionSnapped,
        startAnchorPosition
      );
      rect = RectUtil.resizeRect(rect, this.startResizeRectAnchor.type, delta);
    }
    const rectOnImage: IRect = RectUtil.translate(
      rect,
      data.viewPortContentImageRect
    );
    const LabelName: string = BaseRenderEngine.resolveLabelLineName(
      labelRect.labelId
    );
    const lineColor: string = BaseRenderEngine.resolveLabelLineColor(
      labelRect.labelId,
      true
    );
    const anchorColor: string = BaseRenderEngine.resolveLabelAnchorColor(true);
    this.renderRectWithText(rectOnImage, LabelName, true, lineColor, anchorColor);
  }

  private renderRect(
    rectOnImage: IRect,
    LabelName: string,
    isActive: boolean,
    lineColor: string,
    anchorColor: string
  ) {
    const rectBetweenPixels =
      RenderEngineUtil.setRectBetweenPixels(rectOnImage);
    DrawUtil.drawRectWithFill(
      this.canvas,
      rectBetweenPixels,
      DrawUtil.hexToRGB(lineColor, 0.2)
    );
    DrawUtil.drawRect(
      this.canvas,
      LabelName,
      rectBetweenPixels,
      lineColor,
      RenderEngineSettings.LINE_THICKNESS
    );
    if (isActive) {
      const handleCenters: IPoint[] = RectUtil.mapRectToAnchors(
        rectOnImage
      ).map((rectAnchor: RectAnchor) => rectAnchor.position);
      handleCenters.forEach((center: IPoint) => {
        const handleRect: IRect = RectUtil.getRectWithCenterAndSize(
          center,
          RenderEngineSettings.anchorSize
        );
        const handleRectBetweenPixels: IRect =
          RenderEngineUtil.setRectBetweenPixels(handleRect);
        DrawUtil.drawRectWithFill(
          this.canvas,
          handleRectBetweenPixels,
          anchorColor
        );
      });
    }
  }
  private renderRectWithText(
    rectOnImage: IRect,
    LabelName: string,
    isActive: boolean,
    lineColor: string,
    anchorColor: string
  ) {
    const rectBetweenPixels =
      RenderEngineUtil.setRectBetweenPixels(rectOnImage);
    DrawUtil.drawRectWithFill(
      this.canvas,
      rectBetweenPixels,
      DrawUtil.hexToRGB(lineColor, 0.2)
    );
    DrawUtil.drawRectWithText(
      this.canvas,
      LabelName,
      rectBetweenPixels,
      lineColor,
      RenderEngineSettings.LINE_THICKNESS
    );
    if (isActive) {
      const handleCenters: IPoint[] = RectUtil.mapRectToAnchors(
        rectOnImage
      ).map((rectAnchor: RectAnchor) => rectAnchor.position);
      handleCenters.forEach((center: IPoint) => {
        const handleRect: IRect = RectUtil.getRectWithCenterAndSize(
          center,
          RenderEngineSettings.anchorSize
        );
        const handleRectBetweenPixels: IRect =
          RenderEngineUtil.setRectBetweenPixels(handleRect);
        DrawUtil.drawRectWithFill(
          this.canvas,
          handleRectBetweenPixels,
          anchorColor
        );
      });
    }
  }

  private updateCursorStyle(data: EditorData) {
    if (
      !!this.canvas &&
      !!data.mousePositionOnViewPortContent &&
      !GeneralSelector.getImageDragModeStatus()
    ) {
      const rectUnderMouse: LabelRect = this.getRectUnderMouse(data);
      const rectAnchorUnderMouse: RectAnchor = this.getAnchorUnderMouse(data);
      if (
        (!!rectAnchorUnderMouse &&
          rectUnderMouse &&
          rectUnderMouse.status === LabelStatus.ACCEPTED) ||
        !!this.startResizeRectAnchor
      ) {
        store.dispatch(updateCustomCursorStyle(CustomCursorStyle.RESIZE));
        return;
      } else if (rectUnderMouse) {
        store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
    } else if (RenderEngineUtil.isMouseOverCanvas(data)) {
        if (
          !RenderEngineUtil.isMouseOverImage(data) &&
          !!this.startCreateRectPoint
        )
          store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
        else RenderEngineUtil.wrapDefaultCursorStyleInCancel(data);
        this.canvas.style.cursor = "none";
      } else {
        this.canvas.style.cursor = "default";
      }
    }
  }

  // =================================================================================================================
  // HELPERS
  // =================================================================================================================

  public isInProgress(): boolean {
    return !!this.startCreateRectPoint || !!this.startResizeRectAnchor;
  }

  private calculateRectRelativeToActiveImage(
    rect: IRect,
    data: EditorData
  ): IRect {
    const scale: number = RenderEngineUtil.calculateImageScale(data);
    return RectUtil.scaleRect(rect, 1 / scale);
  }

  private addRectLabel = (rect: IRect) => {
    const activeLabelId = LabelsSelector.getActiveLabelNameId();
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const labelRect: LabelRect = LabelUtil.createLabelRect(activeLabelId, rect);
    imageData.labelRects.push(labelRect);
    store.dispatch(updateImageDataById(imageData.image_id, imageData));
    store.dispatch(updateFirstLabelCreatedFlag(true));
    store.dispatch(updateActiveLabelId(labelRect.id));
  };

  private getRectUnderMouse(data: EditorData): LabelRect {
    const activeRectLabel: LabelRect = LabelsSelector.getActiveRectLabel();
    if (!!activeRectLabel && activeRectLabel.show && this.isMouseOverRectEdges(activeRectLabel.rect, data)) {
      return activeRectLabel;
    }

    const labelRects: LabelRect[] = LabelsSelector.getActiveImageData().labelRects;

    for (let i = 0; i < labelRects.length; i++) {
      if (labelRects[i].show && this.isMouseOverRectEdges(labelRects[i].rect, data)) {
        return labelRects[i];
      }
    }
    return null;
  }

  private isMouseOverRectEdges(rect: IRect, data: EditorData): boolean {
    const rectOnImage: IRect = RectUtil.translate(
      this.calculateRectRelativeToActiveImage(rect, data),
      data.viewPortContentImageRect
    );

    const outerRectDelta: IPoint = {
      x: RenderEngineSettings.anchorHoverSize.width / 2,
      y: RenderEngineSettings.anchorHoverSize.height / 2,
    };
    const outerRect: IRect = RectUtil.expand(rectOnImage, outerRectDelta);

    const innerRectDelta: IPoint = {
      x: -RenderEngineSettings.anchorHoverSize.width / 2,
      y: -RenderEngineSettings.anchorHoverSize.height / 2,
    };
    const innerRect: IRect = RectUtil.expand(rectOnImage, innerRectDelta);

    return (
      RectUtil.isPointInside(outerRect, data.mousePositionOnViewPortContent) &&
      !RectUtil.isPointInside(innerRect, data.mousePositionOnViewPortContent)
    );
  }

  private getAnchorUnderMouseByRect(
    rect: IRect,
    mousePosition: IPoint,
    imageRect: IRect
  ): RectAnchor {
    const rectAnchors: RectAnchor[] = RectUtil.mapRectToAnchors(rect);
    for (let i = 0; i < rectAnchors.length; i++) {
      const anchorRect: IRect = RectUtil.translate(
        RectUtil.getRectWithCenterAndSize(
          rectAnchors[i].position,
          RenderEngineSettings.anchorHoverSize
        ),
        imageRect
      );
      if (
        !!mousePosition &&
        RectUtil.isPointInside(anchorRect, mousePosition)
      ) {
        return rectAnchors[i];
      }
    }
    return null;
  }

  private getAnchorUnderMouse(data: EditorData): RectAnchor {
    const labelRects: LabelRect[] =
      LabelsSelector.getActiveImageData().labelRects;
    for (let i = 0; i < labelRects.length; i++) {
      const rect: IRect = this.calculateRectRelativeToActiveImage(
        labelRects[i].rect,
        data
      );
      const rectAnchor = this.getAnchorUnderMouseByRect(
        rect,
        data.mousePositionOnViewPortContent,
        data.viewPortContentImageRect
      );
      if (!!rectAnchor) return rectAnchor;
    }
    return null;
  }

  private startRectCreation(mousePosition: IPoint) {
    this.startCreateRectPoint = mousePosition;
    store.dispatch(updateActiveLabelId(null));
    EditorActions.setViewPortActionsDisabledStatus(true);
  }
  public cancelLabelCreation() {
    this.startCreateRectPoint = null;
    store.dispatch(updateActiveLabelId(null));
    EditorActions.setViewPortActionsDisabledStatus(false);
  }
  public startRectPositioning(mousePosition: IPoint) {
      this.startCreateRectPoint = mousePosition;
      store.dispatch(updateActiveLabelId(null));
      EditorActions.setViewPortActionsDisabledStatus(true);
    }
  private startRectResize(activatedAnchor: RectAnchor) {
    this.startResizeRectAnchor = activatedAnchor;
    EditorActions.setViewPortActionsDisabledStatus(true);
  }

  private endRectTransformation() {
    this.startCreateRectPoint = null;
    this.startResizeRectAnchor = null;
    EditorActions.setViewPortActionsDisabledStatus(false);
  }
}
